import React, { useState, useEffect } from "react";
import styles from "./FormMap.module.css";
import emailjs from "emailjs-com";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const FormMap = () => {
    const [showPopup, setShowPopup] = useState(false); // State to handle popup visibility
    const [isSending, setIsSending] = useState(false);
    const navigate = useNavigate(); // Use the useNavigate hook

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const utmSource = queryParams.get("utm_source");
    const utmMedium = queryParams.get("utm_medium");

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        // Cleanup function to reset the style when the component is unmounted
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showPopup]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true); // Set to true when starting to send the email

        // Check that at least one checkbox is checked
        const interests = Array.from(e.target.interest)
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);
        if (interests.length === 0) {
            alert("Please check at least one checkbox.");
            setIsSending(false);
            return;
        }

        const formData = {
            name: e.target.name.value,
            phone: e.target.phone.value,
            email: e.target.email.value,
            interest: interests.join(", "), // Join interests into a string
        };

        // Check if any field is empty
        if (!formData.name) {
            alert("Name is not filled.");
            setIsSending(false);
            return;
        }
        if (!formData.phone) {
            alert("Phone number is not filled.");
            setIsSending(false);
            return;
        }
        if (!formData.email) {
            alert("Email is not filled.");
            setIsSending(false);
            return;
        }

        // Why it is sending formdata to zapier instead of salesforce?

        console.log("Form Data:", formData); // Log form data

        // Template ID from your EmailJS dashboard
        const templateId = "template_gdpoicq";

        // Send the email using EmailJS
        emailjs
            .send("service_js1oqrx", templateId, formData, "q0nRvvwDl5D-22Y-F")
            .then((result) => {
                console.log("Success with EmailJS:", result);
                setIsSending(false); // Set back to false on success
                navigate("/thankyou"); // Redirect to the thank you page

                // Send the data to Zapier without Content-Type header
                const zapierWebhookURL =
                    "https://hooks.zapier.com/hooks/catch/3638648/3vmroce/";
                return fetch(zapierWebhookURL, {
                    method: "POST",
                    body: JSON.stringify(formData),
                });
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success with Zapier:", data);
                setIsSending(false); // Set back to false on success
            })
            .catch((error) => {
                console.error("Error:", error);
                // alert(
                //     "An error occurred while submitting the form. Please try again later."
                // );
                setIsSending(false); // Set back to false on error
            });



            // Salesforce Integration:

            
      const url = new URL("https://credvestindiapriavtelimited.my.salesforce-sites.com/ld/leadToSf");

      // Add query parameters
      url.searchParams.append("lastName", formData.name);
      url.searchParams.append("mobile", formData.phone);
      url.searchParams.append("email", formData.email);
      url.searchParams.append("project", "Amaranthine Hyd");
    //   url.searchParams.append("source", "Website");
    //   url.searchParams.append("subsource", "Website");
      url.searchParams.append("source", utmSource||"website");
      url.searchParams.append("subsource", utmMedium||"website");

    //   console.log("My data's are here for salesforce", formData)
      

      fetch(url, {
        method: "POST",
        mode:"cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });


        navigate("/thankyou"); // Redirect to the thank you page


    };

    return (
        <div className={`${styles.section} section`}>
            <div className={`${styles.overlay} overlay`}>
                <h1>Get in touch with us</h1>
                <div className={`${styles.tileContainer} row`}>
                    <div className={`${styles.leftPane} col-11 col-md-4 `}>
                        <form onSubmit={handleSubmit}>
                            <div
                                className={`${styles.inputField} ${styles.name}`}
                            >
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    className={`${styles.input} `}
                                    required
                                />
                            </div>
                            <div className={`${styles.inputField} `}>
                                <label>Mobile Number</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    className={`${styles.input} `}
                                    required
                                />
                            </div>
                            <div
                                className={`${styles.inputField} ${styles.email} `}
                            >
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className={`${styles.input} `}
                                    required
                                />
                            </div>
                            <div
                                className={`${styles.inputField} ${styles.xbhk} `}
                            >
                                I'm Interested in
                                <div className={styles.checkBoxContainer}>
                                    <label className={styles.checkBoxes}>
                                        <input
                                            type="checkbox"
                                            value="3BHK"
                                            name="interest"
                                        />{" "}
                                        3bhk
                                    </label>
                                    <label className={styles.checkBoxes}>
                                        <input
                                            type="checkbox"
                                            value="4BHK"
                                            name="interest"
                                        />{" "}
                                        4bhk
                                    </label>
                                    <label className={styles.checkBoxes}>
                                        <input
                                            type="checkbox"
                                            value="Duplexes"
                                            name="interest"
                                        />{" "}
                                        Duplexes
                                    </label>
                                </div>
                            </div>

                            <div className={styles.submitDiv}>
                                <input
                                    type="submit"
                                    value={isSending ? "Sending..." : "Submit"}
                                    className={styles.submitBtn}
                                    disabled={isSending} // Optionally disable the button when sending
                                />
                            </div>
                        </form>
                    </div>
                    <div className={`${styles.rightPane} col-5`}>
                        <div className={styles.mapWrapper}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.975211869837!2d78.37986459999999!3d17.4129772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb95164b50ae77%3A0x24731ee866182e28!2sQuambiant%20Amaranthine!5e0!3m2!1sen!2sin!4v1691963632022!5m2!1sen!2sin"
                                width="100%"
                                height="100%"
                                allowFullScreen={true}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <a
                                href="https://www.google.com/maps/dir//17.413079571580404, 78.3798753288366"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.viewDirectionButton}
                            >
                                View Direction
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className={styles.popupOverlay}>
                    <div className={styles.popupContent}>
                        <h3>Thank You!</h3>
                        <p>
                            Your request has been submitted. Our team will get
                            back to you soon.
                        </p>
                        <button
                            className={styles.popupButton}
                            onClick={() => setShowPopup(false)}
                        >
                            Thank You
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormMap;
