import React, { useState, useEffect } from "react";
import styles from "./contactBtn.module.scss";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";


// This is for Enquire Now side Btn:

const ContactBtn = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [downloadAfterSubmit, setDownloadAfterSubmit] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const utmSource = queryParams.get("utm_source");
    const utmMedium = queryParams.get("utm_medium");

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        if (isPopupOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isPopupOpen]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true);

        const interests = Array.from(e.target.interest)
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);

        if (interests.length === 0) {
            alert("Please check at least one checkbox.");
            setIsSending(false);
            return;
        }

        const formData = {
            name: e.target.name.value,
            phone: e.target.phone.value,
            email: e.target.email.value,
            interest: interests.join(", "), // Join interests into a string
        };

        if (!formData.name || !formData.phone || !formData.email) {
            alert("Please fill out all fields.");
            setIsSending(false);
            return;
        }

        const templateId = "template_gdpoicq";

        emailjs
            .send("service_js1oqrx", templateId, formData, "q0nRvvwDl5D-22Y-F")
            .then((result) => {
                console.log("Success with EmailJS:", result);

                // Send the data to Zapier without Content-Type header
                const zapierWebhookURL =
                    "https://hooks.zapier.com/hooks/catch/3638648/3vmroce/";
                return fetch(zapierWebhookURL, {
                    method: "POST",
                    body: JSON.stringify(formData), // Browser will set Content-Type automatically
                });
            })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success with Zapier:", data);
                setIsSending(false); // Set back to false on success
                setIsPopupOpen(false);
                if (downloadAfterSubmit) {
                    window.open("/brochure.pdf", "_blank");
                }
                navigate("/thankyou");
            })
            .catch((error) => {
                console.error("Error:", error);
                // alert(
                //     "An error occurred while submitting the form. Please try again later."
                // );
                setIsSending(false);
            });

            
            // Salesforce Integration:

            
      const url = new URL("https://credvestindiapriavtelimited.my.salesforce-sites.com/ld/leadToSf");

      // Add query parameters
      url.searchParams.append("lastName", formData.name);
      url.searchParams.append("mobile", formData.phone);
      url.searchParams.append("email", formData.email);
      url.searchParams.append("project", "Amaranthine Hyd");
    //   url.searchParams.append("source", "Website");
    //   url.searchParams.append("subsource", "Website");
      url.searchParams.append("source", utmSource||"website");
      url.searchParams.append("subsource", utmMedium||"website");

    //   console.log("contact-btn", formData)
      

      fetch(url, {
        method: "POST",
        mode:"cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

        setIsPopupOpen(false);
        navigate("/thankyou"); // Redirect to the thank you page


    };

    const handleButtonClick = (download) => {
        setDownloadAfterSubmit(download);
        setIsPopupOpen(true);
    };

    return (
        <div className={styles.contactContainer}>
            <div className={styles.buttons}>
                <button
                    className={styles.contactBtn}
                    onClick={() => handleButtonClick(false)}
                >
                    Enquire Now
                </button>
                <button
                    className={styles.brochureBtn}
                    onClick={() => handleButtonClick(true)}
                >
                    Download Brochure
                </button>
            </div>

            {isPopupOpen && (
                <div className={styles.popup}>
                    <div className={`${styles.section} section`}>
                        <div className={`${styles.overlay} overlay`}>
                            <div className={`${styles.tileContainer} row`}>
                                <div
                                    className={`${styles.leftPane} col-11 col-md-4`}
                                >
                                    <form onSubmit={handleSubmit}>
                                        <div
                                            className={`${styles.inputField} ${styles.name}`}
                                        >
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className={`${styles.input}`}
                                                required
                                            />
                                        </div>
                                        <div className={`${styles.inputField}`}>
                                            <label>Mobile Number</label>
                                            <input
                                                type="tel"
                                                name="phone"
                                                className={`${styles.input}`}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={`${styles.inputField} ${styles.email}`}
                                        >
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className={`${styles.input}`}
                                                required
                                            />
                                        </div>
                                        <div
                                            className={`${styles.inputField} ${styles.xbhk}`}
                                        >
                                            I'm Interested in
                                            <div
                                                className={
                                                    styles.checkBoxContainer
                                                }
                                            >
                                                <label
                                                    className={
                                                        styles.checkBoxes
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value="3BHK"
                                                        name="interest"
                                                    />{" "}
                                                    3bhk
                                                </label>
                                                <label
                                                    className={
                                                        styles.checkBoxes
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value="4BHK"
                                                        name="interest"
                                                    />{" "}
                                                    4bhk
                                                </label>
                                                <label
                                                    className={
                                                        styles.checkBoxes
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value="Duplexes"
                                                        name="interest"
                                                    />{" "}
                                                    Duplexes
                                                </label>
                                            </div>
                                        </div>
                                        <input
                                            type="submit"
                                            value={
                                                isSending
                                                    ? "Sending..."
                                                    : "Submit"
                                            }
                                            className={styles.submitBtn}
                                            disabled={isSending}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={togglePopup} className={styles.closeBtn}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default ContactBtn;
