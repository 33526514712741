import React, { useEffect } from "react";
// import "./Thankyou.css"; // Import the CSS file

const Thankyou = () => {
    // Redirect to the thankyou.html page as soon as this component is rendered
    useEffect(() => {
        window.location.replace("/thankyou.html"); // Use replace for consistent redirection
    }, []);

    return <div style={{ backgroundColor: "black", height: "100vh" }}></div>;
};

export default Thankyou;
